import $ from 'jquery';
import 'slick-carousel';
import lg from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'animate.css';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


$(function() {
    $('.slick').slick({
        arrows:true,
    });

    lg(document.getElementById('aksuGallery'), {
        plugins: [lgThumbnail, lgZoom],
        selector: 'a',
        thumbnail: true,
        zoom: true,
        lazyLoad: true,
        download:false,
        counter:true,
        enableSwipe:true,
        ariaLabel: {
            close: "Kapat",
            download: "İndir",
            fullscreen: "Tam Ekran",
            next: "Sonraki",
            pause: "Duraklat",
            play: "Oynat",
            previous: "Önceki",
            zoom: "Yakınlaştır"
        }
    });

    $('#toggler').on('click',function() {
        $('#main-menu').toggleClass('active');
    })
});
